export default {
	data() {
		return {
			mode: process.env.NODE_ENV,
		}
	},
	computed: {
		shouldMock() {
			return (
				['development', 'staging'].includes(this.mode) ||
				this.isSuperAdmin
			)
		},
	},
}
