<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
            @breadcrumbs-item-selected="breadCrumbItemSelected"
        ></psi-page-title>
        <applicant-test-toolbar v-if="shouldMock"></applicant-test-toolbar>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4">
            <v-card-text>
                <psi-content-loader v-if="loading"></psi-content-loader>

                <!-- TODO: ONLY RUN THE APPLICATION STEPPER IF NOT COMPLETE -->
                <!-- Setting a dynamic key based on changes in the applicant section or applicant will re-render stepper on section changes -->
                <application-stepper
                    v-if="!loading && Object.keys(applicant).length > 0"
                    :applicant="applicant"
                    :application="applicant.application"
                    :role="role"
                    :key="`${applicant.applicant_id}-${Object.values(
                        applicant.sections
                    ).join('-')}`"
                >
                </application-stepper>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import HomeLink from "@/mixins/homelink";
import Mocking from "@/mixins/mocking";

export default {
    name: "application-edit",
    mixins: [HomeLink, Mocking],
    components: {
        ApplicationStepper: () =>
            import("../components/Application/ApplicationStepper.vue"),
        ApplicantTestToolbar: () =>
            import("../components/Apply/ApplicantTestToolbar"),
    },
    props: {},
    data() {
        return {
            applicantItems: [],
            selectedApplicant: null,
            role: "",
        };
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.findApplicant(to.query);
                this.role = this.$_.get(to, "query.role", "applicant");
            },
        },
        applicant() {
            this.setBreadCrumbs();
        },
    },
    computed: {
        ...mapGetters("Applications", ["applicant", "loading"]),
        breadcrumbs() {
            let breadcrumbs = [
                Object.assign(
                    {
                        text: this.$t("applications.home"),
                        disabled: false,
                    },
                    this.homeUrl
                ),
                // TODO: Enable this based on Roles/Permissions. Don't allow for Students, Program Admin, Instructor
                // {
                //     text: "Applications",
                //     disabled: false,
                //     to: { name: "Applications" },
                // },
            ];
            if (
                this.$_.get(this.applicant, "application.applicants", [])
                    .length === 1
            ) {
                breadcrumbs.push({
                    text: this.$_.get(
                        this.applicant,
                        "application.applicants[0].profile.name",
                        ""
                    ),
                    disabled: false,
                });
            } else {
                breadcrumbs.push({
                    options: {
                        text: "text",
                        value: "value",
                        width: 400,
                        selected: this.selectedApplicant,
                        items: this.applicantItems,
                    },
                });
            }
            return breadcrumbs;
        },
        page() {
            return {
                title:
                    this.$_.get(this.applicant, "profile.name", "").length > 0
                        ? `Edit Application - ${this.$_.get(
                              this.applicant,
                              "profile.name"
                          )}`
                        : "Edit Application",
                icon: "mdi-clipboard-account",
            };
        },
    },
    methods: {
        ...mapActions("Applications", ["findApplicant"]),
        setBreadCrumbs() {
            this.selectedApplicant = this.$_.get(
                this.applicant,
                "applicant_id",
                ""
            );
            this.applicantItems = this.$_.get(
                this.applicant,
                "application.applicants",
                []
            ).map((applicant) => {
                return {
                    text:
                        this.$_.get(applicant, "profile.name", "").length > 0
                            ? `${applicant.profile.name} - ${applicant.type} - (${applicant.order_id})`
                            : `${applicant.email} - ${applicant.type} (${applicant.order_id})`,
                    value: applicant.applicant_id,
                };
            });
        },
        breadCrumbItemSelected(item) {
            if (item.value !== this.applicant.applicant_id) {
                const { params } = this.applicant.application.applicants.find(
                    (applicant) => applicant.applicant_id === item.value
                );
                this.$router.push({
                    name: "Edit Application",
                    query: params,
                });
            }
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>