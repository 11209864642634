var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        },
        on: { "breadcrumbs-item-selected": _vm.breadCrumbItemSelected }
      }),
      _vm.shouldMock ? _c("applicant-test-toolbar") : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _vm.loading ? _c("psi-content-loader") : _vm._e(),
              !_vm.loading && Object.keys(_vm.applicant).length > 0
                ? _c("application-stepper", {
                    key:
                      _vm.applicant.applicant_id +
                      "-" +
                      Object.values(_vm.applicant.sections).join("-"),
                    attrs: {
                      applicant: _vm.applicant,
                      application: _vm.applicant.application,
                      role: _vm.role
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }